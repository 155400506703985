import * as React from 'react'
import { useRouter } from 'next/router'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from 'components/Backdrop'

const HelloReload = () => {
  const router = useRouter()

  React.useEffect(() => {
    router.push('/home')
  }, [router])

  return (
    <div>
      <Backdrop open>
        <CircularProgress style={{ zIndex: 5000 }} />
      </Backdrop>
    </div>
  )
}

export default HelloReload
